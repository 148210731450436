<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/ledgervoucher`"
                title="Ledger Voucher"
                :columns="columns"
                routerpath="/setup/addledgervoucher"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
import axios from '@/components/axios'
const baseApi = process.env.VUE_APP_APIENDPOINT

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search name'
          }
        },
        {
          label: 'Mobile',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Mobile'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Address',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Address'
          }
        },
        {
          label: 'City',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'Pincode',
          field: 'pincode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pincode'
          }
        },
        {
          label: 'State',
          field: 'state',
          filterOptions: {
            enabled: true,
            placeholder: 'Search State'
          }
        },
        {
          label: 'Country',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Country'
          }
        },
        {
          label: 'Schemename',
          field: 'schemename',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Schemename'
          }
        },
        {
          label: 'Plotno',
          field: 'plotno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Plotno'
          }
        },
        {
          label: 'Remarks',
          field: 'remarks',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Remarks'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        gridForm: true,

        formTitle: `${
          this.$route.params.id ? 'Edit Ledger Voucher' : 'Add Ledger Voucher'
        }`,
        submitRouterPath: '/setup/ledger-voucher',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/ledgervoucher`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Date',
            name: 'nextfollowupdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter Type',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Contact Person',
            name: 'contactperson',
            value: '',
            type: 'text',
            placeholder: 'Enter Contact Person',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Mobile',
            name: 'mobile',
            value: '',
            type: 'number',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'text',
            placeholder: 'Enter Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Area',
            name: 'area',
            value: '',
            type: 'text',
            placeholder: 'Enter Area',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'text',
            placeholder: 'Enter Pincode',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Remark',
            name: 'remarks',
            value: '',
            type: 'text',
            placeholder: 'Enter Remarks',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Assign to',
            name: 'assignto',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'assignto',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
          // {
          //   label: "Branch",
          //   name: "branch",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-6",
          //   required: {
          //     required,
          //   },
          //   // inputStyle: "height: 25px; padding: 5px",
          //   options: [],
          // },
        ]
      }
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
